import React from "react"
import './imageLoop.scss'

const ImageLoop = ({ list, className }) => {
  const lists = list?.concat(list).concat(list)
  return (
    <div className={className == 'roll1' ? 'roll_content' : 'roll_content1'} >
      {
        list?.map(v=> 
          (
            <img src={v} className="roll_img" />
          )
        )
      }
    </div>
  )
}

export default ImageLoop
